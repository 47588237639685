import React, { useState } from "react";
import styles from "./Topbar.module.scss";
import logo from "./logo.svg";
import menu from "./menu.svg";
import close from "./close.svg";

import { useNavigate } from "react-router-dom";
import { useEffect, useRef } from "react";

interface SubMenu {
  title: { name: string; url: string };
  items?: { name: string; url: string }[];
}

interface MenuItem {
  title: { name: string; url: string };
  subMenus?: SubMenu[];
}
const menuItems: MenuItem[] = [
  {
    title: { name: "장례식장 소개", url: "/introduction/philosophy" },
    subMenus: [
      {
        title: { name: "소개", url: "/introduction/philosophy" },
        items: [
          { name: "경영이념", url: "/introduction/philosophy" },
          { name: "인사말", url: "/introduction/greeting" },
          { name: "오시는길", url: "/introduction/directions" },
        ],
      },
    ],
  },
  {
    title: { name: "시설 현황", url: "/facilities/1st/funeral-office" },
    subMenus: [
      {
        title: { name: "1층", url: "/facilities/1st/funeral-office" },
        items: [
          { name: "장례사무실", url: "/facilities/1st/funeral-office" },
          { name: "입관실, 안치실", url: "/facilities/1st/preparation-room" },
          { name: "사무국", url: "/facilities/1st/office" },
          {
            name: "봉안함, 위패 접수 및 정산실",
            url: "/facilities/1st/reception",
          },
          { name: "가족 대기실", url: "/facilities/1st/family-waiting-room" },
        ],
      },
      {
        title: { name: "2층", url: "/facilities/2nd/mourning-room" },
        items: [
          { name: "빈소", url: "/facilities/2nd/mourning-room" },
          { name: "가족 휴게실", url: "/facilities/2nd/family-lounge" },
          { name: "식당, 매점", url: "/facilities/2nd/restaurant" },
          { name: "카페", url: "/facilities/2nd/cafe" },
        ],
      },
    ],
  },
  {
    title: { name: "이용안내", url: "/usage/funeral/funeral-items" },
    subMenus: [
      {
        title: {
          name: "장례식장 이용안내",
          url: "/usage/funeral/funeral-items",
        }, // 추가
        items: [
          { name: "장례용품", url: "/usage/funeral/funeral-items" },
          { name: "빈소, 오브제(화환)", url: "/usage/funeral/funeral-hall" },
          {
            name: "봉안함, 위패, 표지석 각인",
            url: "/usage/funeral/memorial-engraving",
          },
          {
            name: "제례상(49상, 기제사)",
            url: "/usage/funeral/memorial-ceremony",
          },
          {
            name: "식당, 매점(꽃), 카페",
            url: "/usage/funeral/dining-facilities",
          },
        ],
      },
      {
        title: {
          name: "부대시설 이용안내",
          url: "/usage/facilities/crematorium",
        }, // 추가
        items: [
          { name: "승화원", url: "/usage/facilities/crematorium" },
          {
            name: "자연장지(수목장, 잔디장)",
            url: "/usage/facilities/natural-burial",
          },
          { name: "추모의 집", url: "/usage/facilities/memorial-house" },
          { name: "유택동산", url: "/usage/facilities/memorial-park" },
        ],
      },
    ],
  },
  {
    title: { name: "장례안내", url: "/guide/procedure/before-death" },
    subMenus: [
      {
        title: { name: "장례절차", url: "/guide/procedure" },
        items: [
          { name: "임종 전", url: "/guide/procedure/before-death" },
          { name: "임종 후", url: "/guide/procedure/after-death" },
          { name: "장례 후", url: "/guide/procedure/after-funeral" },
        ],
      },
      {
        title: { name: "장례 정보", url: "/guide/info" },
        items: [
          { name: "예법", url: "/guide/info" },
          { name: "제례", url: "/guide/info/ancestral-rites" },
        ],
      },
    ],
  },
  {
    title: { name: "공지사항", url: "/notices" },
  },
];

const Topbar: React.FC = () => {
  // 메뉴의 hover 상태를 저장하는 상태
  const [hoveredMenuIndex, setHoveredMenuIndex] = useState<number | null>(null);

  const navigate = useNavigate();

  // 홈으로 가는 명령
  const handleLogoClick = (): void => {
    navigate("/");
  };

  //* 햄버거 메뉴
  // 상태로 메뉴가 열렸는지 여부를 관리
  const [isOpen, setIsOpen] = useState(false);

  // 클릭 핸들러: 상태를 토글
  const handleOpenSubMenu = () => {
    if (window.innerWidth < 1024) {
      // 만약 반응형 웹이면, 버튼이 바뀌게 하고,
      setIsOpen(!isOpen);
    } else {
      // 아니면 페이지를 이동시킨다.
      navigate("/sitemap");
    }
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // useEffect(()=>{
  //   console.log(isOpen)
  //   console.log(windowWidth)
  // },[isOpen,windowWidth]);

  return (
    <header className={styles.topbar_container}>
      <nav className={styles.navbar_conainer}>
        {/* <nav className={styles.logo_nav_container}> */}
        <section className={styles.logo_container}>
          <img
            className={styles.logo_img}
            onClick={handleLogoClick}
            src={logo}
            alt="울산하늘공원장례식장(주)"
          />
        </section>

        {/* 
            // main_container는 숨기기?
          */}
        <section className={styles.main_container}>
          <ul
            className={`${styles.menu_all_container} ${
              isOpen && windowWidth < 1024 ? styles.block : ""
            }`}
          >
            {menuItems.map((menuItem, index) => (
              <li
                key={index}
                className={styles.menus_container}
                onMouseEnter={() => {
                  setHoveredMenuIndex(index);
                }}
                onMouseLeave={() => {
                  setHoveredMenuIndex(null);
                }}
              >
                <span // 장례식장 소개, 시설 현황, 이용 안내, 장례안내, 공지사항
                  className={styles.menu_title}
                >
                  <a
                    onClick={() => {
                      navigate(menuItem.title.url);
                    }}
                  >
                    {" "}
                    {menuItem.title.name}
                  </a>
                </span>

                {menuItem.subMenus ? ( // *서브메뉴가 있어야만 서브메뉴 바 출력 (공지사항)
                  <ul className={styles.sub_menus}>
                    {menuItem.subMenus?.map((subMenu, subIndex) => (
                      <li key={subIndex} className={styles.sub_sub_menus}>
                        {subMenu.title && (
                          <span className={styles.sub_menu_title}>
                            {subMenu.title.name}
                          </span>
                        )}
                        <ul className={styles.sub_menu_items}>
                          {subMenu.items?.map((item, itemIndex) => (
                            <li
                              key={itemIndex}
                              className={styles.sub_menu_item}
                            >
                              <a
                                onClick={() => {
                                  navigate(item.url);
                                  setIsOpen(false);
                                }}
                              >
                                {item.name}
                              </a>
                            </li>
                          ))}
                        </ul>
                      </li>
                    ))}
                  </ul>
                ) : window.innerWidth < 1024 ? ( // 윈도우 사이즈가 반응형이면 공지사항도 나타내기.
                  <ul className={styles.sub_menus}>
                    <li className={styles.sub_sub_menus}>
                      <ul className={styles.sub_menu_items}>
                        <li className={styles.sub_menu_item}>
                          <a
                            onClick={() => {
                              navigate(menuItem.title.url);
                              setIsOpen(false);
                            }}
                          >
                            {menuItem.title.name}
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                ) : null}
                {/* todo : 여기에 코드 넣어야함  */}
              </li>
            ))}
          </ul>
        </section>
        {/* </nav> */}

        <section className={styles.add_menu_container}>
          <img
            onClick={handleOpenSubMenu}
            className={styles.menu_img}
            src={window.innerWidth < 1024 && isOpen ? close : menu}
            alt="울산하늘공원장례식장(주)"
          />
        </section>
      </nav>
    </header>
  );
};

export default Topbar;
