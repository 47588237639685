import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import BannerOverlay from "./BannerOverlay";
import RoomInfoCard from "components/RoomInfoCard/RoomInfoCard";
import Information from "components/information/Information";
import FacilityCarousel from "components/facility/FacilityCarousel";
import MainImage from "components/MainImage/MainImage";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from "./MainPage.module.scss";

// 배너 이미지 인터페이스
interface PopupImage {
  original: string;
  parseImage: string;
}

// 배너 데이터 인터페이스
interface PopupData {
  id: number;
  title: string;
  image: PopupImage[];
  endDate?: string; // 종료일이 있을 수도 있으므로 optional
}

// 장례식장 정보 인터페이스
interface Funeral {
  funeral_name: string;
  age: number | null;
  room: string;
  family: string;
  qkfdls: string; // 발인일
  dlqrhks: string; // 입관일
  wkdwl: string; // 장지
  dlqtlf: string; // 일시
}

const MainPage: React.FC = () => {
  // 오늘 날짜 + 시간
  const today = new Date();
  const week = ["일", "월", "화", "수", "목", "금", "토"];
  const formattedDate = `${today.getFullYear()}. ${
    today.getMonth() + 1
  }. ${today.getDate()}.(${
    week[today.getDay()]
  })  ${today.getHours()}시 ${today.getMinutes()}분`;

  // 장례식장 정보 저장
  const [funerals, setFunerals] = useState<Funeral[]>([]);
  // 화면이 모바일인지 여부
  const [isMobile, setIsMobile] = useState(false);

  // 표시할 배너 목록 (localStorage 등에서 걸러진 후)
  const [visibleBanners, setVisibleBanners] = useState<PopupData[]>([]);

  // 캐러셀(slick) 설정
  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    arrows: true,
    slidesToShow: 3,
    slidesToScroll: 3,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: false,
          arrows: false,
        },
      },
    ],
  };

  // // 로드 시 "아직 개발중" 알림
  // useEffect(() => {
  //   const handleLoad = () => {
  //     alert("아직 개발중인 사이트입니다.");
  //   };

  //   window.addEventListener("load", handleLoad);

  //   return () => {
  //     window.removeEventListener("load", handleLoad);
  //   };
  // }, []);

  // 장례식장 정보 불러오기
  useEffect(() => {
    const url = "https://funein.com/ulsansp/funeral/info/list/api/";
    fetch(url)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        // 무빈소 제거
        for (let i = 0; i < data.length; i++) {
          if (data[i].room.startsWith("무빈소")) {
            data.splice(i, 1);
          }
        }
        setFunerals(data);
      })
      .catch((err) => {
        console.error("Fetch error:", err);
      });
  }, []);

  // 화면 크기 체크 (모바일 여부)
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1024);
    };

    handleResize(); // 최초 실행 시 사이즈 체크
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // 배너 데이터 불러오기
  useEffect(() => {
    fetch("https://ulsanskypark.com/api/banners/list")
      .then((res) => res.json())
      .then((data) => {
        if (data.isSuccess) {
          const bannerList: PopupData[] = data.result.content;

          // 오늘 날짜 (YYYY-MM-DD)
          const todayString = new Date().toISOString().split("T")[0];

          // localStorage에 '오늘 다시 열지 않음' 설정된 배너 제외
          // endDate가 지났으면 제외
          const filtered = bannerList.filter((banner) => {
            // 배너별로 hideBanner_{id}에 기록된 날짜
            const hideDate = localStorage.getItem(`hideBanner_${banner.id}`);

            // endDate가 있는 경우, 오늘 이전이면 제외
            if (banner.endDate) {
              const endDate = new Date(banner.endDate);
              // 오늘 00:00을 구해 비교(배너 만료일이 오늘보다 전이면 표시 X)
              const nowMidnight = new Date();
              nowMidnight.setHours(0, 0, 0, 0);
              if (endDate < nowMidnight) {
                return false;
              }
            }

            // 만약 hideDate가 오늘이라면 표시 안 함
            if (hideDate === todayString) {
              return false;
            }

            return true;
          });

          setVisibleBanners(filtered);
        }
      })
      .catch((err) => {
        console.error("Banner fetch error:", err);
      });
  }, []);

  /**
   * 배너 닫기 핸들러
   * @param bannerId - 닫으려는 배너 ID
   * @param dontShowAgain - 오늘 다시 열지 않음 여부
   */
  const handleCloseBanner = (bannerId: number, dontShowAgain: boolean) => {
    if (dontShowAgain) {
      // 오늘 날짜 저장
      const todayString = new Date().toISOString().split("T")[0];
      localStorage.setItem(`hideBanner_${bannerId}`, todayString);
    }
    // 해당 배너만 visibleBanners에서 제거
    setVisibleBanners((prev) => prev.filter((b) => b.id !== bannerId));
  };

  return (
    <div className={styles.container}>
      {/* 여러 배너를 동시에 표시: 인덱스만큼 10px씩 오른쪽/아래로 offset */}
      {visibleBanners.map((banner, index) => {
        const initialX = 100; // 기본 x = 200 + index * 10
        const initialY = 100; // 기본 y = 200 + index * 10
        return (
          <BannerOverlay
            key={banner.id}
            popup={banner}
            onClose={handleCloseBanner}
            initialX={initialX}
            initialY={initialY}
          />
        );
      })}

      {/* 메인 이미지 */}
      <MainImage />

      {/* 장례식장 고인 현황 */}
      <div className={styles.roomInfoCardBackground}>
        <div className={styles.roomInfoCardSlide}>
          <div className={styles.timeTitle}>현재 {formattedDate}</div>
          <div className={styles.mainTitle}>
            울산하늘공원 장례식장 고인 현황
          </div>
          <div className={styles.sliderContainer}>
            <Slider {...settings}>
              {funerals.map((funeral, index) => (
                <div className={styles.slideItem} key={index}>
                  <RoomInfoCard
                    deceasedName={funeral.funeral_name}
                    roomNumber={funeral.room}
                    chiefMourner={funeral.family.split("#")}
                    burialSite={funeral.wkdwl}
                    casketingTime={funeral.dlqrhks}
                    funeralTime={funeral.qkfdls}
                  />
                </div>
              ))}
              {!isMobile &&
                new Array((3 - (funerals.length % 3)) % 3)
                  .fill(null)
                  .map((_, idx) => (
                    <div
                      className={styles.slideItem}
                      key={`placeholder-${idx}`}
                    >
                      <RoomInfoCard />
                    </div>
                  ))}
            </Slider>
          </div>
        </div>
      </div>

      {/* 시설 현황 */}
      <div className={styles.facility}>
        <div className={styles.subTitle}>시설 현황 바로 가기</div>
        <div className={styles.mainTitle}>울산하늘공원 장례식장 시설 현황</div>
        <FacilityCarousel />
      </div>

      {/* 이용 안내 */}
      <div className={styles.informationBackground}>
        <div className={styles.information}>
          <div className={styles.subTitle}>건전한 장례문화를 선도하는</div>
          <div className={styles.mainTitle}>
            울산하늘공원 장례식장 이용 안내
          </div>
          <Information />
        </div>
      </div>
    </div>
  );
};

export default MainPage;
