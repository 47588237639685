import React, { useState, useEffect } from "react";
import styles from "./MainPage.module.scss";

interface PopupImage {
  original: string;
  parseImage: string;
}

interface PopupData {
  id: number;
  title: string;
  image: PopupImage[];
}

interface BannerOverlayProps {
  popup: PopupData;
  onClose: (id: number, dontShowAgain: boolean) => void;
  initialX: number;
  initialY: number;
}

const BannerOverlay: React.FC<BannerOverlayProps> = ({
  popup,
  onClose,
  initialX,
  initialY,
}) => {
  // 드래그 상태
  const [position, setPosition] = useState({ x: initialX, y: initialY });
  const [isDragging, setIsDragging] = useState(false);
  const [offset, setOffset] = useState({ x: 0, y: 0 });

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setIsDragging(true);
    setOffset({
      x: e.clientX - position.x,
      y: e.clientY - position.y,
    });
  };

  const handleMouseMove = (e: MouseEvent) => {
    if (isDragging) {
      const newX = e.clientX - offset.x;
      const newY = e.clientY - offset.y;
      setPosition({ x: newX, y: newY });
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  useEffect(() => {
    if (isDragging) {
      window.addEventListener("mousemove", handleMouseMove);
      window.addEventListener("mouseup", handleMouseUp);
    } else {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    }
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    };
  }, [isDragging, offset]);

  // 닫기 버튼 (오늘 다시 열지 않음 X)
  const handleClose = () => {
    onClose(popup.id, false);
  };

  // 오늘 다시 열지 않음 버튼
  const handleCloseDontShow = () => {
    onClose(popup.id, true);
  };

  return (
    <div className={styles.popupOverlay}>
      <div
        className={styles.popup}
        style={{
          left: position.x,
          top: position.y,
          transform: "translate(0, 0)",
        }}
      >
        {/* 배너 내용 */}
        {popup.image?.[0] && (
          <img
            src={
              "https://ulsanskypark.com/api/banners/images?fileName=" +
              popup.image[0].original
            }
            alt="배너"
            className={styles.popupImg}
          />
        )}

        {/* 드래그 핸들 (배너 헤더) */}
        <div className={styles.header} onMouseDown={handleMouseDown}>
          {/* 버튼 영역 */}
          <button className={styles.popupButton} onClick={handleCloseDontShow}>
            오늘 다시 열지 않음
          </button>
          <button className={styles.popupButton} onClick={handleClose}>
            닫기
          </button>
        </div>
      </div>
    </div>
  );
};

export default BannerOverlay;
