import axios from "axios";
import styles from "./ArticlePage.module.scss";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

interface Article {
  title: string;
  writer: string;
  createdAt: string;
  viewCount: number;
  content: string;
}

function GetArticleCount(): number {
  return 10;
}

const ArticlePage = () => {
  const [article, setArticle] = useState<Article>();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    axios
      .get("https://ulsanskypark.com/api/notices/get/" + id)
      .then((response) => {
        setArticle(response.data.result);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  return (
    <div className={styles.container}>
      <h2 className={styles.header}>{article?.title}</h2>
      <div className={styles.info}>
        <span>작성자: {article?.writer}</span>
        <span>{article?.createdAt}</span>
        <span>조회수: {article?.viewCount}</span>
      </div>
      <div className={styles.content}>{article?.content}</div>
      <div className={styles.navButton}>
        <button
          style={{ visibility: "hidden" }}
          onClick={() => {
            navigate(
              "/notices/" +
                (parseInt(id || "0") < GetArticleCount()
                  ? parseInt(id || "0") + 1
                  : parseInt(id || "0")
                ).toString()
            );
          }}
        ></button>
        <button
          onClick={() => {
            navigate("/notices");
          }}
        >
          목록
        </button>
        <button
          style={{ visibility: "hidden" }}
          onClick={() => {
            navigate(
              "/notices/" +
                (parseInt(id || "0") > 0
                  ? parseInt(id || "0") - 1
                  : parseInt(id || "0")
                ).toString()
            );
          }}
        ></button>
      </div>
    </div>
  );
};

export default ArticlePage;
