import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import moment from "moment";
import "moment-lunar"; // 음력 변환을 위한 라이브러리
import styles from "./Calenar49th.module.scss";
import prevArrowImg from "./imgs/prevArrow.svg";
import nextArrowImg from "./imgs/nextArrow.svg";

type ValuePiece = Date | null;
type Value = ValuePiece | [ValuePiece, ValuePiece];

const Calendar49th: React.FC = () => {
  const [value, onChange] = useState<Value>(new Date());
  const [nowDate, setNowDate] = useState<string>("날짜");
  const [fortyNineDaysLater, setFortyNineDaysLater] = useState<Value>(
    new Date()
  ); //49재

  // Calculate 재 dates
  const calculateMilestones = (date: Date) => {
    return {
      초재: moment(date).add(6, "days").toDate(),
      "2재": moment(date).add(13, "days").toDate(),
      "3재": moment(date).add(20, "days").toDate(),
      "4재": moment(date).add(27, "days").toDate(),
      "5재": moment(date).add(34, "days").toDate(),
      "6재": moment(date).add(41, "days").toDate(),
      "49재": moment(date).add(48, "days").toDate(),
    };
  };

  const [milestoneDates, setMilestoneDates] = useState(
    calculateMilestones(new Date())
  );

  const handleDateChange = (date: Value = new Date()) => {
    const selectedDate = date as Date;
    onChange(selectedDate); // 선택한 날짜로 상태를 업데이트
    setNowDate(moment(selectedDate).format("YYYY년 MM월 DD일")); // 한글 형식으로 nowDate 업데이트

    // 49일 후 날짜 계산
    const calculatedDate = moment(selectedDate).add(48, "days").toDate();
    setFortyNineDaysLater(calculatedDate);

    // Calculate milestone dates and update state
    setMilestoneDates(calculateMilestones(selectedDate));
  };

  const CalendarBox = styled.div`
    display: flex;
    /* align-items: center; */
    justify-content: center;
    /* width: 525px;
    height: 600px; */
  `;

  const StyleCalendar = styled(Calendar)`
    @font-face {
      font-family: "Pretendard-Medium";
      src: url("https://fastly.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Medium.woff")
        format("woff");
      font-weight: 500;
      font-style: normal;
    }

    @font-face {
      font-family: "Pretendard-Regular";
      src: url("https://fastly.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff")
        format("woff");
      font-weight: 400;
      font-style: normal;
    }

    max-width: 100%;
    height: 100%;
    border: 1px solid #d0d0d0;

    padding: 37.5px 44.5px 85px 44.5px;
    width: 525px;
    height: 575px;

    display: flex;
    /* align-items: center; */
    justify-content: center;
    flex-direction: column;
    // * 제일 상단 타이틀크기 < 2024년 11월 > 전체
    .react-calendar__navigation {
      display: flex;
      height: 24px;
      margin-bottom: 20px;
      column-gap: 15px;
      font-family: "Pretendard-Medium";
    }

    // * 제일 상단 타이틀 글시의 컨테이너
    .react-calendar__navigation__label {
      flex-grow: 0 !important ;
    }

    // * 제일 상단 타이틀 글씨, 2024년 11월
    .react-calendar__navigation__label__labelText {
      font-family: "Pretendard-Medium";
      font-size: 20px;
      color: #000000;
    }

    .react-calendar__navigation button {
      min-width: 24px;
      background-color: none;
    }

    .react-calendar__navigation button:disabled {
      background-color: #e8e8e8;
    }

    .react-calendar__navigation button:enabled:hover,
    .react-calendar__navigation button:enabled:focus {
      background-color: #e8e8e8;
    }

    // * Month 뷰 감싸는 컨테이너 (타이틀 제외 달력 박스 )
    .react-calendar--doubleView,
    .react-calendar__viewContainer {
      width: 436px;
      height: 386px;
    }

    // * Month 뷰 컨테이너
    .react-calendar__month-view {
      width: 436px;
      height: 386px;
    }

    // * 일, 월, 화, 수, 목 , 금 , 토 ,  나와있는 거
    .react-calendar__month-view__weekdays {
      text-align: center;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 0.15em;

      color: #000000;
      font-size: 18px;
      font-family: "Pretendard-Medium";
      font-weight: normal;
    }

    //* 날짜만 있는곳의 컨테이너  1,2,3,4,... ~ 31

    .react-calendar__month-view__days {
      width: 436px;
      height: 345px;
    }

    // * 각 날짜 하나하나 요소 컨테이너
    .react-calendar__tile,
    .react-calendar__month-view__days__day {
      color: #353535;
      font-family: "Pretendard-Regular";
      font-weight: normal;
      font-size: 18px;

      width: 60px;
      height: 70px;
    }

    // * 오늘 날짜
    .react-calendar__tile--now {
      /* background: none; */
    }

    // * 월 / 화 / 수 / 목 / 금 / 토 / 일 중에 하나하나 글자 요소
    abbr {
      text-decoration: none; // 밑에 ... 선 없애주기
    }
    // 일요일 스타일
    .react-calendar__month-view__days__day--weekend:nth-child(7n) {
      color: #ff4545; // 파란색
    }

    // 토요일 스타일
    .react-calendar__month-view__days__day--weekend {
      color: #133e80; // 빨간색
    }

    .react-calendar__year-view .react-calendar__tile,
    .react-calendar__decade-view .react-calendar__tile,
    .react-calendar__century-view .react-calendar__tile {
      padding: 1.2em 0.5em;
    }

    .react-calendar__tile--hasActive {
      color: #353535;
      background-color: #d0d0d0;
      border-radius: 0px;
    }

    //* 날짜 하나하나 hover 될 때
    .react-calendar__tile--hasActive:enabled:hover,
    .react-calendar__tile--hasActive:enabled:focus {
      color: #353535;
      background-color: #d0d0d0;
      border-radius: 0px;
    }

    //* 선택된 날짜
    .react-calendar__tile--active {
      color: #353535;
      background-color: #e9f4f6;
      border-radius: 0px;
    }

    // * 날짜를 클릭하거나, 선택된 날짜 위에 바뀌는 색상
    .react-calendar__tile--active:enabled:hover,
    .react-calendar__tile--active:enabled:focus {
      color: #353535;
      background-color: #e9f4f6;
      border-radius: 0px;
    }

    //* 커스텀 클래스 적용을 위해 49재 날짜일때 스타일 정의 */
    .fortyNineDaysTile {
      color: #353535;
      background-color: #e9f4f6; /* 동일한 배경색 */
      border-radius: 0px;
    }

    // ! * 반응형 제작 *1023)
    @media screen and (max-width: 1023px) {
      max-width: 100%;
      border: 1px solid #d0d0d0;

      padding-top: 38px;
      padding-bottom: 57px;
      padding-left: 7px;
      padding-right: 7px;

      width: 310px;
      height: 575px;

      display: flex;
      /* align-items: center; */
      justify-content: center;
      flex-direction: column;
      // * 제일 상단 타이틀크기 < 2024년 11월 > 전체
      .react-calendar__navigation {
        display: flex;
        height: 24px;
        margin-bottom: 20px;
        column-gap: 15px;
        font-family: "Pretendard-Medium";
      }
      /* 
      // * 제일 상단 타이틀 글시의 컨테이너
      .react-calendar__navigation__label {
        flex-grow: 0 !important ;
      } */

      /* // * 제일 상단 타이틀 글씨, 2024년 11월
      .react-calendar__navigation__label__labelText {
        font-family: "Pretendard-Medium";
        font-size: 20px;
        color: #000000;
      } */

      /* .react-calendar__navigation button {
        min-width: 24px;
        background-color: none;
      }

      .react-calendar__navigation button:disabled {
        background-color: #e8e8e8;
      } */

      /* .react-calendar__navigation button:enabled:hover,
      .react-calendar__navigation button:enabled:focus {
        background-color: #e8e8e8;
      } */

      // * Month 뷰 감싸는 컨테이너 (타이틀 제외 달력 박스 )
      .react-calendar--doubleView,
      .react-calendar__viewContainer {
        width: 296px;
        /* height: 386px; */
      }

      // * Month 뷰 컨테이너
      .react-calendar__month-view {
        width: 296px;
        /* height: 386px; */
      }

      /* // * 일, 월, 화, 수, 목 , 금 , 토 ,  나와있는 거
      .react-calendar__month-view__weekdays {
        text-align: center;
        text-transform: uppercase;
        font-size: 0.15em;

        color: #000000;
        font-size: 18px;
        font-family: "Pretendard-Medium";
        font-weight: normal;
      } */

      //* 날짜만 있는곳의 컨테이너  1,2,3,4,... ~ 31

      .react-calendar__month-view__days {
        width: 296px;
        /* height: 345px; */
      }

      // * 각 날짜 하나하나 요소 컨테이너
      .react-calendar__tile,
      .react-calendar__month-view__days__day {
        /* color: #353535;
        font-family: "Pretendard-Regular";
        font-weight: normal; */
        font-size: 14px;

        width: 36px;
        /* height: 70px; */
      }
    }
  `;

  // * 캘린더 이전 화살표 <
  const CalendarPrevArrow = () => {
    return <img src={prevArrowImg} alt={"<"} />;
  };

  // * 캘린더 이후 화살표 <
  const CalendarNextArrow = () => {
    return <img src={nextArrowImg} alt={">"} />;
  };

  const [year, setYear] = useState<string>("");
  const [month, setMonth] = useState<string>("");
  const [day, setDay] = useState<string>("");

  //* 폼 제출 처리 함수
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault(); // 폼의 이벤트 기본 동작 없앰( 새로고침 등)
    // onChange(new Date(`${year}-${month}-${day}`));

    //* 데이터 업데이트
    const newDate = new Date(`${year}-${month}-${day}`);
    handleDateChange(newDate); // Update state and recalculate milestones
  };

  return (
    <div className={styles.container}>
      <CalendarBox>
        <StyleCalendar
          onChange={handleDateChange} // handleDateChange를 onChange에 직접 연결
          value={value}
          formatDay={(local, date) => moment(date).format("D")}
          // formatYear={(locale, date) => moment(date).format("YYYY")} // 네비게이션 눌렀을때 숫자 년도만 보이게
          formatMonthYear={(locale, date) => moment(date).format("YYYY. MM")} // 네비게이션에서 2023. 12 이렇게 보이도록 설정
          next2Label={null} // 년단위 이동 X
          prev2Label={null} // 년단위 이동 X
          prevLabel={<CalendarPrevArrow />}
          nextLabel={<CalendarNextArrow />}
          tileContent={({ date, view }) => {
            const lunarDate = moment(date).lunar().format("M. D"); // 음력 날짜 계산

            // Check if date matches any milestone and render label
            const milestoneLabel = Object.entries(milestoneDates).find(
              ([label, milestoneDate]) =>
                moment(date).isSame(milestoneDate, "day")
            );

            return (
              <div>
                {milestoneLabel ? (
                  <div className={styles.milestoneLabel}>
                    {milestoneLabel[0]}
                  </div>
                ) : null}
                <div className={styles.lunarDate}>{lunarDate}</div>
              </div>
            );
          }}
          // * 각 ..재 날에 표시
          tileClassName={({ date, view }) =>
            Object.values(milestoneDates).some((milestoneDate) =>
              moment(date).isSame(milestoneDate, "day")
            )
              ? "fortyNineDaysTile" // Apply custom style to milestone dates
              : ""
          }
        ></StyleCalendar>
        {/* <div>{nowDate}</div> */}
        {/* <div>
        49재 :{" "}
        {fortyNineDaysLater &&
        !(Array.isArray(fortyNineDaysLater) || fortyNineDaysLater === null)
          ? moment(fortyNineDaysLater).format("YYYY년 MM월 DD일")
          : "날짜 선택"}
      </div> */}
      </CalendarBox>
      <div className={styles.form_container}>
        <form onSubmit={handleSubmit}>
          <section>
            <div>49재 달력 계산입니다.</div>
            <div>기일(부고일)을 입력해주세요.</div>
          </section>
          <section>
            <div>년</div>
            <input
              required
              // minLength={4}
              maxLength={4}
              type="text"
              placeholder="ex) 2024"
              value={year}
              onChange={(e) => setYear(e.target.value)}
            />
          </section>
          <section>
            <div>월</div>
            <input
              required
              // minLength={2}
              maxLength={2}
              type="text"
              placeholder="ex) 11"
              value={month}
              onChange={(e) => setMonth(e.target.value)}
            />
          </section>
          <section>
            <div>일</div>
            <input
              required
              // minLength={2}
              maxLength={2}
              type="text"
              placeholder="ex) 1"
              value={day}
              onChange={(e) => setDay(e.target.value)}
            />
          </section>

          <button type="submit">확인</button>
        </form>
      </div>
    </div>
  );
};

export default Calendar49th;
