import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import styles from "./NoticePage.module.scss";
import { useEffect, useState } from "react";
import axios from "axios";

interface Article {
  title: string;
  writer: string;
  createdAt: string;
  viewCount: number;
  id: number;
}

const NoticePage = () => {
  const [aritcleLength, setArticleLength] = useState<number>(0);
  const [articles, setArticles] = useState<Article[]>([]);
  const [notification, setNotification] = useState<Article[]>();
  const [curIndex, setcurIndex] = useState<number>(1);
  const [tableIndex, setTableIndex] = useState<number>(0);
  const [searchParams] = useSearchParams();
  const title = searchParams.get("title") || "";
  const content = searchParams.get("content") || "";

  useEffect(() => {
    // axios
    //   .get("https://ulsanskypark.com/api/notices/list?page=1&size=999")
    //   .then((response) => {
    //     const totalElements = response.data.result.totalElements; // totalElements에 접근
    //     setArticleLength(totalElements);
    //   })
    //   .catch((error) => {
    //     console.error("Error:", error);
    //   });
    // // setNotification(GetNotification());
    if (content === "") {
      axios
        .get(
          "https://ulsanskypark.com/api/notices/title?title=" +
            title +
            "&page=1" +
            "&size=999"
        )
        .then((response) => {
          const totalElements = response.data.result.totalElements; // totalElements에 접근
          setArticleLength(totalElements);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } else {
      axios
        .get(
          "https://ulsanskypark.com/api/notices/content?content=" +
            content +
            "&page=1" +
            "&size=999"
        )
        .then((response) => {
          const totalElements = response.data.result.totalElements; // totalElements에 접근
          setArticleLength(totalElements);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  }, []);

  useEffect(() => {
    if (content === "") {
      axios
        .get(
          "https://ulsanskypark.com/api/notices/title?title=" +
            title +
            "&page=" +
            curIndex +
            "&size=10"
        )
        .then((response) => {
          setArticles(response.data.result.content);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } else {
      axios
        .get(
          "https://ulsanskypark.com/api/notices/content?content=" +
            content +
            "&page=" +
            curIndex +
            "&size=10"
        )
        .then((response) => {
          setArticles(response.data.result.content);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  }, [curIndex]); // curIndex가 변경될 때마다 실행

  const navigate = useNavigate();

  return (
    <div className={styles.container}>
      <div className={styles.controls}>
        <p className={styles.pageLength}>
          전체&nbsp;<span>{aritcleLength}</span>건의 게시물이 있습니다.
        </p>
        <form
          className={styles.search}
          onSubmit={(e) => {
            e.preventDefault();
            const search = e.target as HTMLFormElement;
            const searchType = (search[0] as HTMLSelectElement).value;
            const searchWord = (search[1] as HTMLInputElement).value;
            if (searchType === "title") {
              navigate("/notices?title=" + searchWord);
            } else {
              navigate("/notices?content=" + searchWord);
            }
          }}
        >
          <select>
            <option value="title">제목</option>
            <option value="content">내용</option>
          </select>
          <input type="text" placeholder="검색어를 입력하세요" />
          <button type="submit"></button>
        </form>
      </div>

      <table className={styles.articleTable}>
        <thead>
          <tr>
            <th>번호</th>
            <th>제목</th>
            <th>작성자</th>
            <th>작성일</th>
            <th>조회수</th>
          </tr>
        </thead>
        <tbody>
          {notification &&
            notification.map((article, index) => (
              <tr key={index}>
                <td>
                  <span className={styles.notificationLabel}>공지</span>
                </td>
                <td>
                  <a
                    onClick={() => {
                      navigate("/notices/" + article.id.toString());
                    }}
                  >
                    {article.title}
                  </a>
                </td>
                <td>{article.writer}</td>
                <td>{article.createdAt}</td>
                <td>{article.viewCount}</td>
              </tr>
            ))}
          {articles &&
            articles.map((article, index) => (
              <tr key={index}>
                <td>{article.id}</td>
                <td>
                  <a
                    onClick={() => {
                      navigate("/notices/" + article.id.toString());
                    }}
                  >
                    {article.title}
                  </a>
                </td>
                <td>{article.writer}</td>
                <td>{article.createdAt}</td>
                <td>{article.viewCount}</td>
              </tr>
            ))}
        </tbody>
      </table>

      <div className={styles.pagination}>
        <button
          onClick={() => {
            if (tableIndex > 0) {
              setTableIndex(tableIndex - 5);
              setcurIndex(tableIndex);
            }
          }}
        ></button>
        {[...Array(Math.ceil(aritcleLength / 10))]
          .slice(tableIndex, tableIndex + 5)
          .map((_, index) => (
            <label
              htmlFor={`page${index + tableIndex + 1}`}
              key={index + tableIndex}
            >
              {index + tableIndex + 1}
              <input
                type="radio"
                key={index + tableIndex}
                name="page"
                id={`page${index + tableIndex + 1}`}
                value={index + tableIndex + 1}
                defaultChecked={index + tableIndex + 1 === curIndex}
                onClick={() => {
                  setcurIndex(index + tableIndex + 1);
                }}
              ></input>
            </label>
          ))}
        <button
          onClick={() => {
            if (tableIndex + 5 < Math.ceil(aritcleLength / 10)) {
              setTableIndex(tableIndex + 5);
              setcurIndex(tableIndex + 5 + 1);
            }
          }}
        ></button>
      </div>
    </div>
  );
};

export default NoticePage;
