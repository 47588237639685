import "./logoList.scss";

interface Logo {
  src: string;
  url: string;
  description?: string;
}

export default function logoList() {
  const Path = `${process.env.PUBLIC_URL}/MainLayoutImage/logo`;

  const logos: Logo[] = [
    {
      src: `${Path}1.png`,
      url: "https://www.uic.or.kr/main/mainPage.do",
      description: "UIC 메인 페이지",
    },
    {
      src: `${Path}2.png`,
      url: "https://www.ulsan.go.kr/u/rep/main.ulsan",
      description: "울산 대표 홈페이지",
    },
    {
      src: `${Path}3.png`,
      url: "https://www.15774129.go.kr/",
      description: "보건복지 통합 콜센터",
    },
    {
      src: `${Path}4.png`,
      url: "https://www.mohw.go.kr/",
      description: "보건복지부",
    },
  ];

  return (
    <div className="logoList">
      <div className="imagesWrapper">
        {logos.map((logo, index) => (
          <div className="logo" key={index}>
            <a href={logo.url} target="" rel="">
              <img src={logo.src} alt={`logo${index + 1}`} />
            </a>
          </div>
        ))}
      </div>
    </div>
  );
}
