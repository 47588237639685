import { useEffect, useState } from "react";
import styles from "./FuneralItems.module.scss";
import { FuneralData } from "./funeralItemsData";

const FuneralItems: React.FC = () => {
  const [selectedName, setSeletedName] = useState("관");

  // 클릭시 , 선택된 name으로 변경
  const handleSelect = (name: string) => {
    setSeletedName(name);
  };

  interface TableStyles {
    name: string;
    className: string;
  }

  //* 각각 테이블 스타일 목록
  const tableStyles: TableStyles[] = [
    {
      name: "관",
      className: styles.table_coffin,
    },
    {
      name: "수의",
      className: styles.table_shroud,
    },
    {
      name: "고인 용품",
      className: styles.table_deceased,
    },
    {
      name: "상주 용품",
      className: styles.table_mourner,
    },
    {
      name: "기타",
      className: styles.table_miscellaneous,
    },
    {
      name: "현대 상복",
      className: styles.table_modern,
    },
    {
      name: "염습비",
      className: styles.table_enshrouding,
    },
  ];

  return (
    <main className={styles.container}>
      <section>
        <div className={styles.callout}>24시간 연중 무휴</div>
      </section>

      <section className={styles.description_container}>
        <h3 className={styles.subheading}>취급 품목</h3>
        <ul className={styles.description}>
          <li>
            <strong>고인용품:</strong> 수의, 목관, 종이관, 관보, 결관바, 탈지면,
            세정액, 한지, 습신, 연기저귀, 향균시트 등{" "}
          </li>
          <li>
            <strong>상주용품:</strong> 현대 남, 여상복(대여), 와이셔츠, 넥타이,
            벨트, 완장 등
          </li>
        </ul>
      </section>

      <section className={styles.table_all_container}>
        <ul className={styles.select_container}>
          <li
            className={`${styles.select_name} 
                        ${selectedName === "관" ? styles.selected : ""}`}
            onClick={() => {
              handleSelect("관");
            }}
          >
            관
          </li>
          <li
            className={`${styles.select_name} 
                        ${selectedName === "수의" ? styles.selected : ""}`}
            onClick={() => {
              handleSelect("수의");
            }}
          >
            수의
          </li>
          <li
            className={`${styles.select_name} 
                        ${selectedName === "고인 용품" ? styles.selected : ""}`}
            onClick={() => {
              handleSelect("고인 용품");
            }}
          >
            고인 용품
          </li>
          <li
            className={`${styles.select_name} 
                        ${selectedName === "상주 용품" ? styles.selected : ""}`}
            onClick={() => {
              handleSelect("상주 용품");
            }}
          >
            상주 용품
          </li>
          <li
            className={`${styles.select_name} 
                        ${selectedName === "기타" ? styles.selected : ""}`}
            onClick={() => {
              handleSelect("기타");
            }}
          >
            기타
          </li>
          <li
            className={`${styles.select_name} 
                        ${selectedName === "현대 상복" ? styles.selected : ""}`}
            onClick={() => {
              handleSelect("현대 상복");
            }}
          >
            현대 상복
          </li>
          <li
            className={`${styles.select_name} 
                        ${selectedName === "염습비" ? styles.selected : ""}`}
            onClick={() => {
              handleSelect("염습비");
            }}
          >
            염습비
          </li>
        </ul>

        {["관", "수의", "고인 용품", "상주 용품", "기타"].includes(
          selectedName
        ) ? (
          <section className={styles.table_container}>
            <table
              className={
                tableStyles.find((style) => style.name === selectedName)
                  ?.className
              }
            >
              <thead>
                <th>순번</th>
                <th>장례용품명</th>
                <th>규격</th>
                <th>단위</th>
                <th>가격</th>
              </thead>
              <tbody>
                {/* 해당 object의 title을 찾은 뒤 인덱스를 반환한다. 그것을 토대로 화면에 띄워준다. */}
                {FuneralData.find(
                  (item) => item.title === selectedName
                )?.item.map((item, index) => (
                  <tr>
                    <td>{item.id}</td>
                    <td>{item.name}</td>
                    <td>{item.standard}</td>
                    <td>{item.unit}</td>
                    <td>{item.price}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </section>
        ) : selectedName === "현대 상복" ? (
          <section className={styles.table_container}>
            <table className={styles.table_modern}>
              <thead>
                <th>구분</th>
                <th>순번</th>
                <th>장례용품명</th>
                <th>단위</th>
                <th>가격</th>
              </thead>
              <tbody>
                {/* 해당 object의 title을 찾은 뒤 인덱스를 반환한다. 그것을 토대로 화면에 띄워준다. */}
                {FuneralData.find(
                  (item) => item.title === selectedName
                )?.item.map((item, index) => (
                  <tr>
                    {item.category && <td rowSpan={3}>{item.category}</td>}
                    <td>{item.id}</td>
                    <td>{item.name}</td>
                    <td>{item.unit}</td>
                    <td>{item.price}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </section>
        ) : selectedName === "염습비" ? (
          <section className={styles.table_container}>
            <table className={styles.table_enshrouding}>
              <thead>
                <th colSpan={2}>구분</th>
                <th>규격</th>
                <th>단위</th>
                <th colSpan={2}>가격</th>
              </thead>
              <tbody>
                <tr>
                  <td rowSpan={2} colSpan={2}>
                    염습비
                  </td>
                  <td rowSpan={2}>인건비</td>
                  <td rowSpan={2}>회 </td>
                  <td>일반</td>
                  <td>200,000</td>
                </tr>
                <tr>
                  <td>사고</td>
                  <td>250,000</td>
                </tr>
              </tbody>
            </table>
          </section>
        ) : null}
      </section>
    </main>
  );
};

export default FuneralItems;
