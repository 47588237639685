import Calendar49th from "./Calendar49th/Calendar49th";
import styles from "./MemorialCeremony.module.scss";
import thirty from "./imgs/30s.png";
import eight from "./imgs/8s.png";

//제례상(49상, 기제사)
const MemorialCeremony: React.FC = () => {
  return (
    <main className={styles.container}>
      <section className={styles.header_container}>
        <div className={styles.callout}>예약전화: 052)264-1017</div>
        <p>
          울산 하늘공원 장례식장은 제례상(49상,기제사) 차림과 전문 장례지도사의
          진행으로 제례진행을 도와드리고 있습니다.
        </p>
      </section>

      <article className={styles.description_container}>
        <h3 className={styles.subheading}>49재 달력 계산</h3>
        <section>
          <Calendar49th />
        </section>
      </article>
      <article className={styles.description_container}>
        <h3 className={styles.subheading}>제례상</h3>
        <ul className={styles.description}>
          <li>제례상(49상, 기제사)차림상 예시</li>
        </ul>
        <section className={styles.table_container}>
          <table>
            <thead>
              <th>예시 사진</th>
              <th>품목</th>
            </thead>
            <tbody>
              <tr>
                <td>
                  {" "}
                  <img src={thirty} alt="30만상" />{" "}
                </td>
                <td>
                  {" "}
                  사과, 배 , 오렌지, 수박, 조기, 전(5종), <br />
                  <br />
                  나물(3종), 대추, 밤, 곶감, 약과, 영양산자, <br />
                  <br />
                  북어포, 떡갈비, 떡, 탕국, 밥, 제례주, 생수
                </td>
              </tr>
            </tbody>
          </table>
        </section>
      </article>

      <article className={styles.description_container}>
        <h3 className={styles.subheading}>제물 포장</h3>
        <ul className={styles.description}>
          <li>일회용 박스로 제물을 포장해 드립니다.</li>
        </ul>
        <section className={styles.table_container}>
          <table>
            <thead>
              <th>예시 사진</th>
              <th>품목</th>
            </thead>
            <tbody>
              <tr>
                <td>
                  {" "}
                  <img src={eight} alt="8만상(포장 제물)" />{" "}
                </td>
                <td>
                  {" "}
                  사과, 배 , 오렌지, <br />
                  <br />
                  대추, 밤, 곶감, 약과, 영양산자, <br />
                  <br />
                  북어포, 제례주 , 생수,포장박스
                </td>
              </tr>
            </tbody>
          </table>
        </section>
      </article>

      <article className={styles.description_container}>
        <h3 className={styles.subheading}>참고사항</h3>
        <ul className={styles.description}>
          <li>
            설, 추석(명절상) <strong>예약제 운영</strong>을 하고 있습니다.
          </li>
          <li>
            스님(인근사찰) 제례 진행을 가족분들 요청으로 가능 합니다.{" "}
            <strong>(비용 별도발생)</strong>
          </li>
          <li>
            모든 제물은 제례가 끝난 후 포장해 드립니다.{" "}
            <strong>(현장 취식불가)</strong>
          </li>
        </ul>
      </article>
    </main>
  );
};

export default MemorialCeremony;
