import { Routes, Route, BrowserRouter, HashRouter } from "react-router-dom";
import styles from "./App.module.scss";
import MainPage from "pages/Mainpage/MainPage";
import Topbar from "components/TopBar/Topbar";
import Footer from "components/footer/footer";
import LogoList from "components/logoList/logoList";
import DirectionPage from "pages/Introduction/Directions/DirectionsPage";
import PhilosophyPage from "pages/Introduction/Philosophy/Philosophy";
import Sidebar from "components/PageFrame/PageFrame";
import CafePage from "pages/Facilities/Cafe/CafePage";
import FamilyLoungePage from "pages/Facilities/FamilyLounge/FamilyLoungePage";
import FuneralOfficePage from "pages/Facilities/FuneralOffice/FuneralOfficePage";
import PreparationRoomPage from "pages/Facilities/PreparationRoom/PreparationRoomPage";
import OfficePage from "pages/Facilities/Office/OfficePage";
import ReceptionPage from "pages/Facilities/Reception/RecptionPage";
import FamilyWaitingRoomPage from "pages/Facilities/FamilyWaitingRoom/FamilyWaitingRoomPage";
import MourningRoomPage from "pages/Facilities/MourningRoom/MourningRoomPage";
import RestaurantPage from "pages/Facilities/Restaurant/RestaurantPage";
import GreetingPage from "pages/Introduction/Greeting/Greeting";
import FuneralItems from "pages/Usage/FuneralItems/FuneralItems";
import MemorialCeremony from "pages/Usage/MemorialCeremony/MemorialCeremony";
import FuneralHall from "pages/Usage/FuneralHall/FuneralHallPage";
import DiningFacilities from "pages/Usage/DiningFacilities/DiningFacilities";
import MemorialParkPage from "pages/Usage/MemorialPark/MemorialParkPage";
import MemorialHouse from "pages/Usage/MemorialHouse/MemorialHouse";
import AfterFuneral from "pages/Procedure/AfterFuneral/AfterFuneral";
import AncestralRitesPage from "pages/Guide/AncestralRites/AncestralRitesPage";
import NoticePage from "pages/Notice/NoticePage";
import ArticlePage from "pages/Notice/ArticlePage";
import Info from "pages/Guide/Info/Info";
import Crematorium from "pages/Usage/Crematorium/Crematorium";
import Inscription from "pages/Usage/Inscription/Inscription";
import NaturalBurial from "pages/Usage/NaturalGround/NaturalGround";
import DeathbedAfter from "pages/Procedure/deathbed/after/DeathbedAfter";
import DeathbedBefore from "pages/Procedure/deathbed/before/DeathbedBefore";
import SiteMapPage from "pages/SiteMap/SiteMap";

function App() {
  return (
    <div className={styles.container}>
      <BrowserRouter>
        <Topbar></Topbar>
        <Routes>
          <Route path="/" element={<MainPage />} />
        </Routes>
        <div style={{ flex: 1 }}>
          <Routes>
            {/* 메인 페이지 */}
            {/* 소개 페이지 */}
            <Route
              path="/introduction"
              element={<Sidebar type="introduction" />}
            >
              <Route path="directions" element={<DirectionPage />} />
              {/* 인사말 페이지 */}
              <Route path="greeting" element={<GreetingPage />} />
              {/* 경영이념 페이지 */}
              <Route path="philosophy" element={<PhilosophyPage />} />
            </Route>
            {/* 시설 페이지 */}
            <Route path="/facilities" element={<Sidebar type="facilities" />}>
              <Route
                path="1st/funeral-office"
                element={<FuneralOfficePage />}
              />
              <Route
                path="1st/preparation-room"
                element={<PreparationRoomPage />}
              />
              <Route path="1st/office" element={<OfficePage />} />
              <Route path="1st/reception" element={<ReceptionPage />} />
              <Route
                path="1st/family-waiting-room"
                element={<FamilyWaitingRoomPage />}
              />
              <Route path="2nd/mourning-room" element={<MourningRoomPage />} />
              <Route path="2nd/cafe" element={<CafePage />} />
              <Route path="2nd/family-lounge" element={<FamilyLoungePage />} />
              <Route path="2nd/restaurant" element={<RestaurantPage />} />
            </Route>
            {/* 이용안내 페이지 */}
            <Route path="/usage" element={<Sidebar type="usage" />}>
              <Route path="funeral/funeral-items" element={<FuneralItems />} />
              <Route
                path="funeral/memorial-ceremony"
                element={<MemorialCeremony />}
              />
              <Route path="funeral/funeral-hall" element={<FuneralHall />} />
              <Route
                path="funeral/dining-facilities"
                element={<DiningFacilities />}
              />
              <Route
                path="facilities/memorial-park"
                element={<MemorialParkPage />}
              />
              <Route
                path="facilities/crematorium"
                element={<Crematorium /> /* 승화원 */}
              />
              <Route
                path="funeral/dining-facilities"
                element={<DiningFacilities />}
              />
              <Route
                path="facilities/memorial-house"
                element={<MemorialHouse />}
              />
              <Route
                path="funeral/memorial-engraving"
                element={<Inscription />}
              />
              <Route
                path="facilities/natural-burial"
                element={<NaturalBurial />}
              />
            </Route>
            {/* 절차 페이지 */}
            <Route path="/guide" element={<Sidebar type="procedure" />}>
              <Route
                path="procedure/after-funeral"
                element={<AfterFuneral />}
              />
              <Route path="info" element={<Info />} />
              <Route
                path="info/ancestral-rites"
                element={<AncestralRitesPage />}
              />
              <Route
                path="procedure/before-death"
                element={<DeathbedBefore />}
              />
              <Route path="procedure/after-death" element={<DeathbedAfter />} />
            </Route>
            <Route path="/notices" element={<Sidebar type="notices" />}>
              <Route path="" element={<NoticePage />} />
              <Route path=":id" element={<ArticlePage />} />
            </Route>
            <Route path="/sitemap" element={<Sidebar type="sitemap" />}>
              <Route path="" element={<SiteMapPage />} />
            </Route>
          </Routes>
        </div>
        <LogoList></LogoList>
        <Footer></Footer>
      </BrowserRouter>
    </div>
  );
}

export default App;
